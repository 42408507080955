/* src/App.css */.App {
  text-align: center;
  font-family: Arial, sans-serif;
}

.navbar {
  margin-bottom: 20px;
}

.carousel-container {
  margin-bottom: 40px;
}

.about-section {
  padding: 40px0;
  background-color: #f8f9fa;
}

.about-sectionh2 {
  margin-bottom: 20px;
}

.teachers-section {
  padding: 40px0;
}

.teachers-sectionh2 {
  margin-bottom: 20px;
}

.teacher-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
}

.teacher-card {
  background-color: #f4f4f4;
  border-radius: 8px;
  box-shadow: 02px8pxrgba(0, 0, 0, 0.1);
  margin: 15px;
  padding: 20px;
  width: 250px;
  text-align: left;
}

.teacher-cardimg {
  max-width: 100%;
  border-radius: 8px;
}

.teacher-cardh2 {
  margin: 10px0;
}

.teacher-cardp {
  margin: 5px0;
}

.teacher-cardul {
  list-style-type: none;
  padding: 0;
}

.teacher-cardulli {
  background-color: #ddd;
  border-radius: 4px;
  padding: 5px;
  margin: 3px0;
}

.footer {
  padding: 20px0;
  text-align: center;
}
.fixed-image {
  width: 100%;
  height: 200px; 
  object-fit: cover; 
  background-color: white; 
  display: flex;
  align-items: center;
  justify-content: center;
}

.fixed-image img {
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
}

.fixed-image-placeholder {
  width: 100%;
  height: 100%;
  background-color: #ddd; /* Placeholder color for no image */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #888;
  font-size: 1.5rem;
}
.carousel-container .carousel-item img {
  width: 100%;
  height: 500px; 
  object-fit: cover;
}

.footer {
  background-color: #343a40;
  padding: 20px 0;
}

.footer-item {
  display: flex;
  align-items: center;
}

.footer-item a {
  color: white;
  font-size: 18px;
  margin-left: 10px;
}

.footer-item a:hover {
  text-decoration: underline;
}
